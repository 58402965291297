.Allblogs{
    width: 100%;
height: 872px;
flex-shrink: 0;
background: #01101E;
background-image: url(./Images/blogcont.png);
background-repeat: no-repeat;
}
.mainblogcon{
    max-width: 1218px;
    margin: auto;
}
.topnav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 37px;
}
.navicons{
    display: flex;
    gap: 24px;
    align-items: center;
}
.titleblogs{
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
}
.blog-left{
    max-width: 798px;
}
.small_blue_date{
    color: #0095ED;
font-family: "Open Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 14.4px */
padding-top: 24px;
}
.blog_Title{
    color: #FFF;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 140%; /* 33.6px */
max-width: 777px;
margin-top: 10px;
}
.blog_para{
    color: rgba(255, 255, 255, 0.60);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 25.6px */
max-height: 780px;
margin-top: 10px;
}
.single_explain_title{
    color: #FFF;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 800;
line-height: 160%; /* 28.8px */
}
.blog_right{
    max-width: 384px;
}
.margt{
    margin-top: 27px;
}
.cardsofblog{
    max-width: 1218px;
    margin: auto;
    padding-top: 80px;
}
.blog_section_title{
    color: #374147;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 28.8px */
}
.cardscontainer{
    display: flex;
}
.induvidual_cards{
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-right: 1px solid #E0E0E0;
    margin-top: 24px;
    margin-bottom: 80px;
}
.blogerimage{
    width: 280px;
}
.induvidual_cards:first-child{
    padding-left: 0px;
}
.induvidual_cards:last-child{
    border-right: none;
}
.induvidualblog_title{
    color: #374147;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 140%; /* 28px */
max-width: 257px;
}
.para_of_induvidual_blog{
    color: rgba(55, 65, 71, 0.75);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 25.6px */
max-width: 279px;
}
.BlueBlogs{
    width: 100%;
height: 675px;
flex-shrink: 0;
background: #01101E;
background-image: url(./Images/blogcont.png);
background-repeat: no-repeat;
}
.titleof_blue{
    color: #FFF;
text-align: center;
font-family: Heebo;
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: 120%; /* 48px */
}
.BlueBlogs .induvidualblog_title{
    color: #fff;
}
.BlueBlogs .para_of_induvidual_blog{
    color: rgba(255, 255, 255, 0.75);
    margin-top: 10px;
}
.BlueBlogs .induvidual_cards{
    border-right: none;
}
.Bloglist{
    max-width: 1218px;
    margin: auto;
    padding-bottom: 100px;
}
.ListCard{
    border-top: 1px solid #E0E0E0;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.listfirstblog{
    display: flex;
    gap: 30px;
    margin-top: 35px;
}
.listimage{
    width: 206px;
height: 125px;
border-radius: 4px;
}
.bloglist_title{
    color: #383838;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 24px */
margin-top: 10px;
}
.paraoflist{
    color: rgba(55, 65, 71, 0.75);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 25.6px */
max-width: 589px;
margin-top: 10px;

}
.BlogDetail{
width: 100%;
background: #01101E;
background-image: url(./Images/blogcont.png);
background-repeat: no-repeat;
height: 446px;
}
.gotohomeblog{
    display: flex;
    gap: 20px;
    align-items: center;
}
.homeforblog{
    color: #FFF;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
}
.detailimageblog{
    margin-top: 64px;
}
.main_detail_blog{
    display: flex;
    gap: 68px;
    max-width: 1218px;
    margin: auto;
    margin-top: 130px;
}
.social_icons{
    display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
}
.blog_text_side{
    max-width: 1001px;
}
.main_text_blog{
    margin-top: 40px;
    margin-bottom: 40px;
}
.main_detail_blog p {
    color: #666D72;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
}
.blog_explain_title{
    color: #374147;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 140%; /* 33.6px */
}
.Blogfooter{
    height: 100px;
    background: #0C5FA1;

  background-image: url(./Images/footerbg.png);
  background-size: cover;
width: 100%;


}
.footernav{
    display: flex;
width: 1212px;
justify-content: space-between;
align-items: center;
margin: auto;
padding-top: 30px;
}
.alllinksfor_footer{
    display: flex;
align-items: flex-start;
gap: 55px;
}
.alllinksfor_footer span{
    color: #FFF;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
}
.frieightblog{
    color: #FFF;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
}
.rightside{
    padding-left: 32px;
    border-left: 1px solid #E0E0E0;
}
.most_read{
    max-width: 280px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 16px;
    margin-top: 16px;
}
.most_read:last-child{
    border-bottom: none;
}
@media (max-width:1250px) {
    .mainblogcon, .Bloglist, .cardsofblog{
        max-width: 1008px;
    }
    .main_detail_blog{
        gap: 32px;
        max-width: 1008px;

    }
    .footernav{
        max-width: 1008px;
    }

    .blog_text_side{
        max-width: 777px;
    }
    .detailimageblog{
        max-width: 1008px;
    }
    .blog-left{
        max-width: 592px;
    }
    .blog-left img{
        max-width: 592px;
    }
    .cardscontainer{
        flex-wrap: wrap;
    }
    .BlueBlogs{
        height: auto;
    }
}
@media (max-width:786px) {
    .titleblogs{
        flex-wrap: wrap;
       justify-content: center;
    }
    .detailimageblog {
        max-width: 90vw;
        height: 403px;
        display: flex;
        justify-content: center;
        object-fit: cover;
        border-radius: 16px;
    }
    .Allblogs{
        height: auto;
        
    }
    .mainblogcon{
        max-width: 100vw;
    }
    .cardscontainer{
        justify-content: center;
    }
    .blog_section_title{
        display: flex;
        justify-content: center;
    }
    .blog-left{
        max-width: 95vw;
    }
    .blog-left img{
        max-width: 95vw;
        height: 405px;
        object-fit: cover;
        border-radius: 19px;
    }
    .blogerimage{
        width: 95vw;
    }
    .para_of_induvidual_blog ,.induvidualblog_title{
        max-width: 95vw;

    }
    .Allblogs{
        padding-bottom: 40px;
    }
    .induvidual_cards{
margin-bottom: 0px;
border-right: 0px;
border-top: 1px solid #E0E0E0;
padding-top: 16px;
    }
    .induvidual_cards:first-child{
        border-top: none;
    }
    .listfirstblog img{
        display: none;
    }
    .coveroflist{
        width: 100vw;
        }
    .listfirstblog{
        width: 90vw;

        margin: auto;
       }
       .topnav {
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    .navicons{
        width: 100%;
        justify-content: center;
    }
    .footernav{
        flex-direction: column;
        width: 100vw;
    }   
    .alllinksfor_footer{
        flex-direction: column;
        gap: 33px;
    } 
    .Blogfooter{
        height: auto;
        background: #00101D;
    }
    .footernav{
align-items: flex-start;
padding-left: 40px;
gap: 40px;
padding-bottom: 50px;
}
.main_detail_blog{
    flex-direction: column;
    margin-top: 181px;
}
.social_icons{
    flex-direction: row;
    justify-content: center;
}
.blog_text_side{
    max-width: 90vw;
    margin: auto;
}
.coverfordetailblog{
    width: 100vw;
    display: flex;
    justify-content: center;
}
.gotohomeblog {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 90vw;
    justify-content: space-between;
}
.ListCard{
    flex-direction: column;
}
.most_read {
    max-width: 90vw;
}
}