.form-wraper{
    width: 1216px;
    flex-shrink: 0;
    border-radius: 16px;
background: #FFF;
margin-top: 50px;
padding: 24px 40px;
}
.form-wraper label{
    font-family: "Nunito Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
}
.form-wraper input{
    height: 56px;
    margin-bottom: 20px;
}
.formbtn button:first-child a{
    color: #000000 ;

}
.formbtn button:first-child{
    color: #fbf9fa ;

    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    border-radius: 8px;
border: 2px solid #E9E9E9;
background: #E9E9E9;
padding: 16px 40px;
}
.formbtn button:last-child:hover{
    background: #0C94E4;
    color: #fff;
}
.formbtn button:first-child:hover {
    background: #0C94E4;
    color: #fff;
}
.formbtn button:first-child:hover a{
    color: #fff;
}
.formbtn button:last-child{
    padding: 16px 40px;
font-family: "Nunito Sans";
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 24px; 
border-radius: 8px;
background: #E9E9E9;
color: #000000 ;
}
.regisbtn-wraper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: 10px;
}
.btnforhide{
    font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 21.79px;
letter-spacing: 0.05em;
background-color: #fff;
border:1px solid #5c5c5c;
border-radius: 8px;
}

.regisbtn-wraper .regbtn{
    color: #FFF;
cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    border-radius: 50px;
border: 2px solid #0095ED;

background: #0095ED;
display: flex;
width: 147px;
height: 46px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
}
.regisbtn-wraper .levelbtn{
    color: #2C292A;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    border-radius: 50px;
border: 2px solid #E9E9E9;

background: #E9E9E9;
display: flex;
width: 147px;
height: 46px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
}
.regisbtn-wraper .paymentbtn{
    color: #2C292A;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    border-radius: 50px;
border: 2px solid #E9E9E9;

background: #E9E9E9;
display: flex;
width: 147px;
height: 46px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
}
.textforstate{
    font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.05em;
text-align: left;

}
.statement{
    align-items: center;
    gap: 20px;
}
.bluearea{
    font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.05em;
text-align: left;
cursor: pointer;
color:  #0095ED;

}
.form-wraper2{
width: 1216px;
border-radius: 16px;
background: #FFF;
display: inline-flex;
height: auto;
padding: 32px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
flex-shrink: 0;
margin-top: 30px;

}
.form-wraper2 input{
    height: 56px;
    margin-bottom: 10px;
}
.form-wraper2 label{
    font-family: "Nunito Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
}
.formlevel{
    width: 100%;
height: 79px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #D8D4D5;

background: #FFF;
display: flex;
justify-content: space-between;
align-items: center;
}
.basic{
    color: #2C292A;


font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 19.2px */
letter-spacing: 0.8px;
}
.changehed{
    color: #2C292A;
cursor: pointer;

font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 19.2px */
letter-spacing: 0.8px;
text-decoration-line: underline;
}
.form2ad{
    display: flex;
    justify-content: center;
}
.herohieght{
    height: auto;
}
.forpaymentpage .Herosection{
    background-size: auto;
}
@media (max-width:1340px) {
    .form-wraper{
        max-width: 90vw;
        margin: auto;
        margin-top: 20px;
    }
}
@media (max-width:768px) {
    .form-wraper{
        width: 95vw;
        height: auto;
        margin: auto;
        margin-top: 40px;
    }
    .Herosection{
        height: auto;
    }
    .regisbtn-wraper .levelbtn {
width: 81px;
}
.regisbtn-wraper .regbtn{
    width: 81px;
}
.regisbtn-wraper .paymentbtn{
    width: 81px;
}
.forstarted .transparent, .forstarted .transparentmobile{
    margin-top: 50px;
}
.form-wraper2{
width: 95vw;
height: auto;
}
.formlevel{
    width: 100%;
}
.formbtn button:first-child{
    padding: 10px;
}
.formbtn button:last-child{
    padding: 10px;
}
}

.centermodal{
display: flex;
justify-content: center;
flex-direction: column;
}
.centermodal img{
width: 52px;
height: 52px;

}
.forimg{
    display: flex;
    justify-content: center;
}
.successbtn2{
    border-radius: 8px;
background: linear-gradient(269deg, #0095ED 0.27%, #90DAFF 99.73%);
display: flex;
width: 330px;
height: 56px;
padding: 24px;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;
margin-top: 20px;
cursor: pointer;
border: none;
}
.btnforpay{
    border-radius: 8px;
    background: linear-gradient(269deg, #0095ED 0.27%, #90DAFF 99.73%);
    display: flex;
    height: 56px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.32px;
    margin-top: 20px;
    cursor: pointer;
    border: none;
}
.strongdiv{
    color: #0A0A0A;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 160% */
}
.textexpl{
    color: #696969;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}