.Herosection {
    max-width: 100%;
    background-image: url(./images/Background.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1250px;
    overflow-x: none;
  }
  .item_nav{
    text-decoration: underline;
  }
.sectioncolor{
  color: #fff !important;
  max-width: 934px !important;
}
  body {
    background-color: #F9F9FA;
    /* overflow-x: hidden; */
  }
  .monthbtn{
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  .price_title{
    color: #2C292A;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 38.4px */
letter-spacing: 1.6px;
  }
  .price_title span{
    color: #374147;
font-family: "Open Sans";
font-size: 16.628px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.954px */
letter-spacing: 0.831px;
  }
  .freetrailversion{
    color: #0095ED;
font-family: "Open Sans";
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 24px; /* 200% */
border-radius: 99px;
background: rgba(0, 149, 237, 0.15);
height: 25px;
padding:0px 10px 0px 10px ;
  }
  .nav-item a {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
  }
  
  .nav-link:focus,
  .nav-link:hover {
    color: white;
  }
  
  .navbar-nav {
    gap: 43px;
  }
  
  .getstartedbtn {
    display: flex;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 2px solid #FFF;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.7px;
    background-color: inherit;
  }
  
  .linkbtn {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
  }
  
  .centered_content {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;
  }
  
  .sectionhero {
    margin: auto;
  }
  
  .skyrocket {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
  }
  
  .datasoft {
    background: linear-gradient(268deg, #0095ED 39.86%, #90DAFF 70.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 2.4px;
  }
  
  .coverofrocker {
    max-width: 901px;
    margin-top: 77px;
  
  }
  
  .explainationtext {
    max-width: 756px;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    margin: auto;
  }

  .freebtn {
    display: flex;
    width: 375px;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #0095ED;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
    margin: auto;
  }
  
  .moneyback {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 8px;
  }
  
  .feature_div {
    display: flex;
    align-items: center;
    gap: 42px;
  }
  
  .navcontainer {
    display: flex;
    justify-content: space-between;
    width: 1603px;
    margin: auto;
  }
  
  .About-us-section {
    display: flex;
    justify-content: space-between;
    max-width: 1216px;
    margin: auto;
  position: relative;
  bottom: 78px;
  }
  
  .aboutheading {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 37px;
    max-width: 577px;
  
  }
  
  .aboutus {
    color: #0095ED;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 16.8px */
    letter-spacing: 0.7px;
  }
  
  .texthead {
    color: #374147;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
  }
  
  .heading_section_about {
    max-width: 534px;
  }
  
  .faqpara {
    margin-top: 37px;
  }
  
  .contactbuttons {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 44px;
  }
  
  .faqsection {
    display: flex;
    margin-top: 139px;
    justify-content: space-between;
  
  }
  
  /* Override last-of-type styling for accordion */
  .accordion-item:last-of-type {
    border-bottom-right-radius: 16px;
    /* or specify default value */
    border-bottom-left-radius: 16px;
    /* or specify default value */
    border-top-right-radius: 16px;
    /* or specify default value */
    border-top-left-radius: 16px;
    /* or specify default value */
  }
  
  .accordion-item {
    display: flex;
    width: 593px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    border: 2px solid #E9E9E9;
    background: #F9F9FA;
  }
  
  .phonenumberbox {
    display: flex;
    height: 108px;
    padding: 43px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 16px;
    background: rgba(0, 149, 237, 0.15);
  }
  
  .phone {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
    letter-spacing: 0.8px;
  }
  
  .exactnumber {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 24px */
    letter-spacing: 1px;
  }
  
  .paracontent {
    color: #374147;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    max-width: 492px;
  }
  
  .bluecontent {
    width: 389px;
    height: 227px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #043B61;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
  }
  
  .numberhead {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
  }
  
  .for_about_page .bluecontent {
    background: rgba(0, 149, 237, 0.15);
  }
  
  .numberexplain {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 24px */
    letter-spacing: 2px;
    margin-top: 5px;
  }
  
  .transpotation {
    max-width: 1216px;
    margin: auto;
    margin-top: 0px;
  }
  
  .coveroftranspotation {
    text-align: center;
  }
  
  .paraoftranspo {
    color: #374147;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    max-width: 636px;
    margin: auto;
  }
  
  .coveroftranspotation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 40px;
  }
  
  .card1 {
    position: sticky;
    top: 0px;
  }
  
  .card2 {
    position: sticky;
    top: 30px;
  }
  
  .card3 {
    position: sticky;
    top: 60px;
  }
  
  .card4 {
    position: sticky;
    top: 100px;
  }
  
  .card5 {
    position: sticky;
    top: 120px;
    padding-top: 100px;
  
    background-color: #F9F9FA;
  }
  
  .cardstack {
    position: relative;
    /* Change to relative positioning */
    transition: transform 0.3s ease;
    /* Add a transition for smooth animation */
  }
  
  .cardstack:nth-child(1) {
    transform: translateY(0px);
    /* Adjust the translateY for each card */
  }
  
  .cardstack:nth-child(2) {
    transform: translateY(5px);
  }
  
  .cardstack:nth-child(3) {
    transform: translateY(10px);
  }
  
  .cardstack:nth-child(4) {
    transform: translateY(15px);
  }
  
  .cardstack:nth-child(5) {
    transform: translateY(20px);
  }
  
  .positionstick {
    position: sticky;
    top: 0;
  }
  
  .anuallbuttons {
    margin: auto;
    display: flex;
    width: 694px;
    height: 56px;
    padding: 0px 6px;
    justify-content: space-between;
    gap: 40px;
    border-radius: 99px;
    border: 1px solid #D8D4D5;
    background: #FFF;
    align-items: center;
  }
  
  .monthly {
    display: flex;
    width: 347px;
    height: 46px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #374147;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
    cursor: pointer;
  }
  
  .savesection {
    border-radius: 99px;
    background: rgba(0, 149, 237, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0095ED;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    /* 200% */
    width: 82px;
    height: 26px;
    margin-left: 10px;
  }
  .savesection2 {
    border-radius: 99px;
    background: rgba(0, 149, 237, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0095ED;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    /* 200% */
    width: 82px;
    height: 26px;
    margin-left: 10px;
  }
  
  .activebtn {
    display: flex;
    width: 318px;
    height: 46px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    background: #0095ED;
    color: white !important;
  }
  
  .activebtn .savesection {
    background-color: #fff;
    color: #374147;
  }
  .activebtn .savesection2 {
    background-color: #fff;
    color: #374147;
  }
  
  .homeimage {
    text-align: center;
    margin-top: 70px;
  }
  
  .coverofprice {
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    width: 384px;
    box-shadow: 0px 24px 30px 0px rgba(0, 33, 60, 0.10);
    height: 622px;
   
  
  }
  .poinsshownmain2{
    padding: 32px;
  }
  .poinsshownmain{
    padding-left: 32px;
    /* padding-right: 32px; */
  }
  
  .slider-container {
    margin: auto;
    max-width: 1216px;
    /* margin-top: 50px; */
  }
  
  .starcontent {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  
  .bluebox {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #0095ED;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .starttext {
    color: #374147;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 38.4px */
    letter-spacing: 1.6px;
    margin-top: 24px;
  }
  
  .priceofplan {
    color: #374147;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
    margin-top: 24px;
  }
  
  .month {
    color: #374147;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 1px;
  }
  
  .getbtn {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 2px solid #0095ED;
    color: #0095ED;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-top: 24px;
  }
  
  .pointsshown {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    color: #374147;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .Emphsasis {
    display: flex;
    /* align-items: center; */
    gap: 70px;
  }
  
  .emphytext {
    color: #374147;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 5px;
  }
  
  .pofemphy {
    color: #374147;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    margin-top: 6px;
  }
  
  .reviewcontainer {
    max-width: 1216px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 160px;
  }
  
  .leftofreview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 37px;
    max-width: 527px;
  }
  
  .loren1500 {
    background: linear-gradient(268deg, #0095ED 39.86%, #90DAFF 70.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 2.4px;
  }
  
  .paraofreview {
    color: #374147;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    max-width: 492px;
  }
  
  .imageofreview {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .numberofcustomer {
    color: #374147;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
  }
  
  .individual-review {
    border-radius: 16px;
    border: 3px solid #E9E9E9;
    background: #FFF;
    box-shadow: 0px 24px 30px 0px rgba(0, 33, 60, 0.10);
    width: 487px;
    height: 372px;
    flex-shrink: 0;
    padding: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .cardreview {
    position: sticky;
    top: 50px;
  }
  
  .cardelem1 {
    position: sticky;
    top: 100px;
  }
  
  .cardelem2 {
    position: sticky;
    top: 130px;
  }
  
  .cardelem3 {
    position: sticky;
    top: 160px;
  }
  
  .reviewtext {
    color: #374147;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 28.8px */
    max-width: 434px;
  }
  
  .persons_detail {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .nameofperson {
    color: #374147;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .designation {
    color: #374147;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  
  .faq_section {
    width: 100%;
    min-height: 573px;
    background: #00101D;
    background-image: url(./images//faqback.png);
    background-position: bottom;
    background-repeat: no-repeat;
    position: sticky;
    margin-top: 30px;
  }
  
  .accordion-item {
    align-self: flex-start;
    /* Ensure each item maintains its own height */
  
  }
  
  .accordion-button {
    color: #374147;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 28.8px */
  }
  
  .headingfaq {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 38.4px */
    letter-spacing: 1.6px;
    margin: auto;
    margin-top: 51px;
  }
  
  .paraforfaq {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    max-width: 806px;
    margin: auto;
  }
  
  .faqheading {
    max-width: 734px;
    margin: auto;
    text-align: center;
    padding-top: 50px;
  }
  
  .headingoffaq {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
    margin-top: 5px;
  
  }
  
  .poffaq {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 28.8px */
    margin-top: 5px;
  }
  
  .accordioncus-item {
    display: flex;
    width: 593px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    background: #F9F9FA;
    overflow: hidden;
    /* Hide overflowing content */
    transition: max-height 0.3s ease;
    /* Add transition for smooth animation */
    max-height: 100px;
    /* Set initial max height */
  }
  
  .accordioncus-item.active {
    max-height: 200px;
    /* Set max height when active */
  }
  
  .accordioncus {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
  }
  
  .accordioncus-itemsi {
    color: #374147;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 28.8px */
    width: 479px;
  
  }
  
  .accordioncus-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .footerof {
    height: 840px;
    width: 100%;
    background: linear-gradient(to top, #01111E 519px, #F9F9FA 0px);
    background-position: bottom;
    position: sticky;
    background-color: #F9F9FA;
  }
  
  .freestart {
    width: 1216px;
    height: 537px;
    flex-shrink: 0;
    border-radius: 32px;
    background: #fff;
    margin: auto;
    /* border: 1px solid #E9E9E9; */
    padding-top: 40px;
  }
  
  .abovetofooter {
    background-color: #F9F9FA;
    height: 100px;
    position: sticky;
  }
  
  .firstdiv {
    display: flex;
  
  }
  
  .greenbox {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 6.64px;
    background: #02A7BD;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 134px;
    margin-top: 30px;
  }
  
  .purplebox {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 6.64px;
    background: #8989FF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 207px;
    margin-top: 30px;
  }
  
  .darkbluebox {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 6.64px;
    background: #0E5187;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 77px;
  }
  
  .bluebox2 {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 6.64px;
    background: #0095ED;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 217px;
  }
  
  .textboxfooter {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 606px;
    text-align: center;
    margin: auto;
  }
  
  .footer_para {
  
    color: #84949E;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
  }
  
  .freebutton {
    display: flex;
    width: 253px;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #0095ED;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-top: 61px;
    margin-left: 40px;
  
  }
  .footerimage{
    display: flex;
    justify-content: center;
    margin-top: 26px;
  }
  .footerimage img {
   width: 774px;
  }
  
  .realfooter {
    max-width: 1216px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 50px;
  }
  
  .copyright {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */
    letter-spacing: 0.7px;
    width: 204px;
    margin-top: 102px;
  }
  
  .home ul li {
    list-style: none;
    color: white;
    margin-top: 16px;
    color: rgba(255, 255, 255, 0.75);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 13px;
    max-width: 203px;
  }
  
  .home ul {
    padding-left: 0px;
  
  }
  .navbar{
    padding-top: 20px;
  }
  
  .headhome {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }
  
  .coverofalllinks {
    display: flex;
    gap: 80px;
  }
  
  .getsta {
    display: flex;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 2px solid #FFF;
    background: #FFF;
    color: #01101E;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.7px;
  }
  .getsta{
    transition: transform 1s ease; /* Smooth transition for the zoom effect */

  }
  .getsta:hover{
    color: #fff;
    background-color: #0095ED;
    border: 2px solid #0095ED;
  }
  .zoom-in {
    transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
  }
  
  .zoom-in:hover {
    transform: scale(1.1); /* Scale the element to 110% of its original size */
  }
  
  .hoverlink:hover{
    color: #0095ED;
     text-decoration: underline;
  }
  .socialicons {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 100px;
  }
  
  /* .coverofemp .slider-container {
    margin-top: 40px;
  } */
  
  .formobile {
    display: none;
  }
  
  .homeimage {
    position: relative;
  }
  
  .coverofhomecards {
    position: relative;
    z-index: 1;
    /* Ensure the cards stay behind the image */
  }
  
  .homeimage .scrollerimage {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    /* Ensure the image stays on top */
    max-width: 95vw;
  }
  
  /* .scrollerimage:hover+.coverofhomecards .callcardsreview {
    height: 300px;
  } */
  
  .callcardsreview {
    display: flex;
    max-width: 1300px;
    margin: auto;
    height: 250px;
    justify-content: center;
  }
  
  .singlecard {
    width: 340px;
    height: 230px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 16px 16px 20px 0px rgba(0, 33, 60, 0.10);
  
  }
  
  .strongtext {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    max-width: 172px;
  }
  
  .animatedcard {
    text-align: left;
    padding: 10px;
  }
  
  /* slick dots */
  /* Override default dot styles */
  .slick-dots {
    bottom: 10px;
    /* Adjust the position of dots */
  }
  
  /* Customize dot size and appearance */
  .slick-dots li {
    margin: 0 5px;
    /* Adjust the spacing between dots */
  }
  
  .slick-dots li button {
    background-color: grey;
    /* Default dot color */
  }
  
  /* Style the active dot */
  .slick-dots li.slick-active button {
    background-color: #0095ED;
    /* Active dot color */
    border-radius: 50%;
  }
  
  .slick-dots li button:before {
    font-size: 0px !important;
  }
  
  .slick-dots {
    bottom: 64px !important;
  }
  
  .slick-dots li {
    width: 30px;
    height: 30px;
    background-color: #bbbdbf;
    /* Default dot color */
    border-radius: 50%;
  
  }
  
  .aboutusheading {
    color: #FFF;
  
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
  
  }
  
  .aboutuspera {
    color: #FFF;
  
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    width: 492px;
  }
  
  
  
  .standerheding {
    color: #374147;
  
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
  }
  
  .standerpera {
    color: #374147;
  
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
  }
  
  .containerwidth {
    max-width: 1216px;
    margin: auto;
  }
  .transporttextg{
    max-width: 711px;
  }
  .standerdheading2 {
    color: #374147;
    max-width: 679px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 57.6px */
    letter-spacing: 2.4px;
    text-align: center;
  }
  
  .standerdpera2 {
    color: #374147;
    max-width: 679px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    text-align: center;
  }
  
  
  
  .btnbutton {
    display: flex;
    width: 375px;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #0095ED;
    color: #FFF;
  
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
  }
  
  
  .arroww{
    width: 95%;
    display: flex;
    align-items: center;
  }
  html{
    overflow-x: hidden;
  }
  .coverofparagrap{
    margin-top: -270px;
  }
  .parasta{
    max-width: 492px;
  }
  .headingside{
    max-width: 672px;
  }
  .secoundhead {
    margin-left: 20%;
  }
  .colobtn:hover{
    background-color: #0095ED !important;
  }
  .startedbtn{
    background-color: white;
    display: flex;
  width: 278px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #0095ED;
  color: #0095ED;
  
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  }

  .forpasswortype{
    border: 1px solid #dee2e6;
    width: 100%;
    height: 55px;
    border-radius: 8px;
    display: flex;
  
  }
  .forpasswortype input{
    /* border: none; */
    width: 90%;
    height: 45px !important;
    margin-top: 5px;
    margin-left: 5px;
    border: none;
  }
  .forpasswortype input:focus{
    outline: none;
  }
  .forrela{
    position: relative;
    bottom: 32px;
  }
  @media (max-width:1500px) {
    .sectioncolor{
      color: #000 !important;
    }
  }
  @media (max-width:1440px) {
    .navcontainer {
      max-width: 1217px;
    }
  
  }
  
  @media (max-width:1250px) {
    .headingside{
      max-width: 472px;
    }
    .texthead{
      font-size: 40px;
    }
    .containerwidth,
    .navcontainer {
      max-width: 1008px;
    }
  .homehero .Herosection{
    height: 1250px;
  }
    .accordion-item {
      width: 485px;
    }
  
    .heading_section_about {
      width: 526px !important
    }
  
    .imgsection {
      width: 362px;
    }
  
    .phonenumberbox {
      width: 488px;
    }
  
    .contactbuttons {
      gap: 32px;
    }
  
    .realfooter {
      width: 90%;
    }
    .coverofprice{
      width: 320px;
      height: 635px;
    }
    .abouthero .Herosection{
      height: 1250px;
    }
    /* .coverofsmallcoursel .Herosection{
      height: auto;
    } */
  }
  @media (max-width:1100px) {
    .flexitems{
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
    }
    .flexitems .aboutheading{
      max-width: 100vw;
    }
    .flexitems .aboutimage img{
      width: 80vw;
    }
    .coverofparagrap{
      margin-top: 200px;
    }
    .headingside, .parasta{
      max-width: 679px;
      text-align: center;
    }
  }
  @media (max-width:1080px) {
    .footbtn {
      flex-direction: column !important;
    }
  }
@media (max-width:1030px) {
  .coverofparagrap{
    margin-top: 680px;
  }
  .for_about_page .bluecontent {
    background: #043B5F;
}
.About-us-section{
  justify-content: center;
}

}
  @media (max-width:1010px) {
  
    .containerwidth,
    .navcontainer {
      max-width: 940px !important;
    }
  
    .phonenumberbox {
      width: 87% !important;
    }
  
    .accordion-item {
      width: 450px;
    }
  
  }
  
  @media (max-width:1008px) {
    .faqsection {
      flex-direction: column;
      align-items: center;
    }
  
    .contactbuttons {
      margin-bottom: 20px;
    }
  
    .contactbuttons {
      flex-direction: column;
    }
  
    .phonenumberbox {
      width: 100% !important;
    }
  
    .accordioncus {
      justify-content: center !important;
    }
  
    .imgsection {
      width: 56% !important;
    }
  
    .accordion-item {
      width: 480px;
    }
  
  }
  
  @media (max-width: 990px) {
    .realfooter {
      flex-direction: column;
    }
  
    .footerof {
      height: 100%;
    }
  
    .footbtn {
      display: none !important;
    }
  
    .socialicons {
      display: none;
    }
  
    .copyright {
      display: none;
    }
  
    .footbtn2 {
      display: block !important;
      display: flex !important;
      gap: 20px;
      align-items: center;
  
    }
  
    .copyright2 {
      display: block !important;
    }
  
    .realfooter {
      align-items: center;
    }
  }
  
  
  @keyframes rotateIcon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotateIcon 0.3s linear; /* Apply the animation */
  }
  .smallnav{
    background: #01101E;
  }
  .nav_items_canvas{
    display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  margin-top: 40px;
  }
  .item_nav{
    color: #FFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  }
  .item_line_border{
    width: 90vw;
    border-top: 1px solid #032748;
    
  }
  .item_button{
    display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #FFF;
  color: #FFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  }
  .yearlybuttons{
    display: flex;
  width: 694px;
  height: 56px;
  padding: 0px 6px;
  justify-content: center;
  gap: 40px;
  border-radius: 99px;
  border: 1px solid #D8D4D5;
  background: #FFF;
  margin: auto;
  margin-bottom: 50px;
  align-items: center;
  }
  .monthbtn{
    display: flex;
  width: 347px;
  height: 46px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #374147;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  }
  .navbar-hidden {
    top: 56px;
    position: fixed;
    z-index: 999999999;
  }
  
  .navbar-visible {
    top: 0;
    position: fixed;
    z-index: 999999999;
  
  }
  .mobilever{
    display: none;
  }
  .flexitems{
    display: flex;
    justify-content: space-between;
  }
  @media (max-width:1250px) {
    .About-us-section{
      max-width: 95vw;
    }
    .bluecontent{
  width: 33%;
    }
    /* .freestart{
      width: 1000px;
    } */
  }
  @media (max-width:1030px) {
    .About-us-section{
      flex-direction: column;
    }
    .bluecontent{
      width: 90vw;
    }
  }
  .secoundhead{
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  @media (max-width:786px) {
    .Herosection {
      background-image: url(./images/Mobileimage.png);
      height: 1254px !important;
    }
    .flexitems {
      flex-direction: column;
    }
    .mobilever{
      display: block;
    }
    .desktopver{
      display: none;
    }
    .indual {
      width: 50%;
    }
  .secoundhead{
    margin-top: 20px;
  }
    .otheindi {
      margin-bottom: 50px;
    }
    .labler{
      margin-top: 16px;
    }
    .labler2{
      margin-top: 33px;
    }
  
    .Emphsasis {
      gap: 40px;
    }
    .formobilbot{
      margin-bottom: 50px;
    }
    .forbottominmobile{
      margin-top: -106px !important;
    }
  .formobilbot2{
    margin-bottom: 135px;

  }
    .formobile {
      display: block;
    }
  
    .fordesk {
      display: none;
    }
    .slick-dots{
      position:static !important;
    }
    .homeani {
      width: 90vw;
      margin-top: 60px;
    }
  .secoundhead{
    margin-left: 0px;
  }
    /* .About-us-section {
      position: relative;
      bottom: 135px;
    } */
    .transpotation{
      margin-top: -123px;
    }
    .standerdpera2{
      text-align: left;
    }
    .standerdheading2{
      text-align: left;
      font-size: 32px;
    }
    .navbar {
      width: 100vw;
      margin: auto;
      /* position: fixed; */
      padding: 10px;
      top: 0px;
      background: #01101E;
      z-index: 99999;
    }
  
    .sectionhero {
      width: 90%;
      margin-top: 65px;
    }
    .feature_div{
      gap: 16px;
    }
    .bluecontent {
      width: 90vw;
    }
  
    .freebtn {
      width: 90vw;
    }
  
    .skyrocket {
      font-size: 36px;
    }
  
    .datasoft {
      font-size: 36px;
  
    }
  
    .coveroftranspotation {
      width: 90vw;
      margin: auto;
      gap: 37px;
    }
  .coverofparagrap{
    margin-top: 390px;
  }
  .for_about_page .bluecontent{
    background: #043B61;
  }
  .standerheding{
    font-size: 32px;
  }
    .reviewcontainer {
      width: 90vw;
    }
  
    .feature_div {
      flex-direction: column;
    }
  
    .homeimage img {
      width: 90vw;
    }
  .abovetoabout .About-us-section{
    bottom: 0px;
  }
    .About-us-section {
      width: 90vw;
      flex-direction: column;
      position: relative;
      bottom: 180px;
    }
  
    .aboutimage img {
      width: 90vw;
  
    }
  .card4{
  top: 40px;
  }
    .positionstick img {
      width: 90vw;
      margin: auto;
    }
  
    .positionstick {
      text-align: center;
    }
  
    .anuallbuttons {
      width: 90vw;
    }
  
    .Emphsasis {
      flex-wrap: wrap;
      width: 90vw;
      margin: auto;
    }
  
    .reviewcontainer {
      flex-wrap: wrap;
  
    }
  
    .individual-review {
      width: 90vw;
      height: 427px;
    }
  
    .rightsideofreview {
      margin: auto;
    }
  
    .coverofprice {
      width: 95%;
      margin: auto;
      height: 642px;
      margin-left: 74px;
    }
    .coverofemp{
      margin-top: 40px;
    }
    .poinsshownmain{
      padding-left: 20px;
      /* padding-right: 0px; */
    }
  
    .texthead {
      font-size: 32px;
    }
  
    .faqsection {
      align-items: start;
    }
  
    .heading_section_about {
      width: 100% !important;
      max-width: 100% !important;
      margin: auto;
    }
  
    .aboutusheading {
  
      font-size: 32px
    }
    .faqpara{
      width: 100%;
    }
    .contactbuttons{
      display: none;
    }
    .imgsection{
      width: 90vw !important;
      margin: auto;
      
    }
    .faqsection{
      padding-top: 150px;
      margin-top: 0px;
    }
  .mainheadingfaq{
    display: none;
  }
  .mainheadingfaq2{
    display: block !important;
    align-items: start;
    width: 100%;
   
    /* padding: 50px; */
  }
  .headingfaq2{
    color: #FFF;
  
  text-align: center;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  letter-spacing: 1.6px;
  }
  .paraforfaq2{
    color: #FFF;
  
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  }
  .contactbuttons2{
    display: block !important;
  }
  .phonenumberbox{
    width: 90vw !important;
    margin: auto;
    margin-bottom: 32px;
    margin-top: 32px;
  }
  .faq_section {
    padding-bottom: 80px;
  }
  .accordioncus{
    justify-content: center !important;
  
  }
  .logomain{
    text-align: center;
    width: 100%;
  }
  .getsta{
    width: 177px;
  }
  .coverforpagefaq .Herosection{
   
  height: auto !important;
  
  }
  .standersmartop  .coverofparagrap{
    margin-top: 200px;
  }
  .footbtn2{
    gap: 48px;
  }
  .logomain{
    padding-top: 50px;
  }
  .coverofalllinks{
    flex-direction: column;
  }
  .realfooter{
    align-items: start;
  }
  .reviewcontainer {
    margin-top: 20px;
  }
  .revbot{
    margin-bottom: 100px;
  }
  
  .footerof{
    /* background-image: url('./images/footerbg.png'); */
    background: linear-gradient(to top, #01111E 85%, #F9F9FA 0px);

    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: bottom;
    position: sticky;
  }
  .coveroftranspotation{
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .txtmar{
    margin-top: -30px;
  }
  .accordion-item {
    width: 90%;
  }
  .freestart2{
    width: 327px;
    height: 590px;
    margin: auto;
    border-radius: 50px;
    overflow: hidden;
  }
  .indual{
    width: 39vw;
    height: 200px;
  }
  .textboxfooter{
    width: 276px;
    margin: auto;
    margin-top: 30px;
    margin-left: 25px;
  }
  .freebutton{
    width: 276px;
    margin-left: 0;
    margin-top: 0;
  }
  .darkbluebox{
    margin-right: 0;
    width: 42px;
    height: 42px;
  }
  .aboutuspera{
width: 90vw;
  }
  .greenbox{
    margin: 0;
    width: 42px;
    height: 42px;
  }
  .bluebox2{
    margin-right: 0 !important;
    width: 42px;
    height: 42px;
  }
  .productheading{
    width: 90vw !important;
  }
  .purplebox{
    margin: 0 ;
    width: 42px;
    height: 42px;
  }
  
  .svgboxs svg{
  width: 21px;
  }
  .footerimage2{
  text-align: center;
  margin-top: 5px;
  }
  
  .freestart{
    display: none;
  }
  .freestart2{
    display: block !important;
  }
  .coverofalllinks{
    gap: 56px;
  }
  .containerwidth{
    max-width: 90vw !important;
  }
  .paracontent{
    margin-bottom: 32px !important;
  }
  .bluecontent{
    margin-top: 21px;
  }
  .paraoftranspo{
    padding-bottom: 37px;
  }
  .card5{
    padding-top: 100px;
    padding-bottom: 24px;
  }
  .savesection{
  
  display: none;
  }
  .savesection2{
    width: 50px;
    display: block !important;
  }
  .slider-container{
    margin-top: 0px;
  }
  .getbtn{
  width: 90%;
  }
  /* .cardelem1{
    position: relative;;
    top: 40px;
  } */
  .headingoffaq{
    font-size: 32px;
  }
  .reviewtext{
    margin-bottom: 0;
  }
  .faqheading {
    max-width: 90vw;
  }
  .arroww{
    width: 95%;
  }
  .abovetofooter{
    height: 80px;
  }
  .faqheading{
    padding-top: 80px;
  }
  .textmr{
    margin-top: -35px;
  }
  .bluecontent{
    height: 150px;
  }
  }
  .navbar-toggler-icon{
    filter: brightness(90);
  }
  @media (max-width:500px) {
    .standersmartop  .coverofparagrap{
      margin-top: 140px;
    }
  }