.Chamelonhome{
    max-width: 912px;
    margin: auto;
    display: flex;
    overflow: hidden;
}
.navcon{
    width: 160px;
    border-radius: 16px 0px 0px 16px;
background: #00101D;
height: 506px;
background-color: url(./navback.png);
background-position: left;
text-align: center;}
.navcon img{
    margin-top: 40px;
    width: 92px;
}
.smallcont{
    display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
margin-top: 66px;
}
.innersmall{
    width: 95px;
height: 16px;
border-radius: 88px;
background: #012441;
}
.mainaredash{
    width: 752px;
height: 506px;
flex-shrink: 0;
border-radius: 0px 16px 16px 0px;
background: #F9F9FA;
padding-top: 16px;
padding-left: 32px;
padding-right: 26px;
display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 27px;
}
.mailerside{
    display: flex;
align-items: center;
gap: 20px;
}
.salesnav{
    display: flex;
width: 694px;
justify-content: space-between;
align-items: center;
}
.salesa{
    color: #374147;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.chame{
    color: #374147;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 800;
line-height: normal;
position: relative;
bottom: 3px;
}
.namesales{
    display: flex;
}
.forborder{
    border-left: 1px solid #DFE1E2;
    height: 20px;
}
.whatsnew{
    color: #374147;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
align-items: center;
gap: 8px;
}
.newsearch{
    display: flex;
width: 688px;
justify-content: space-between;
align-items: center;
}
.buttonforsearch{
    display: flex;
height: 32px;
padding: 16px;
justify-content: center;
align-items: center;
gap: 16px;
border-radius: 4px;
background: #0C94E4;
color: #FFF;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.dotnumber{
    color: #374147;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.dashinforcormer{
    width: 694px;
height: auto;
flex-shrink: 0;
border-radius: 16px;
border: 1px solid #DFE1E2;
background: #FFF;

}
.titlesectiondash{
    display: flex;
width: 694px;
height: 40px;
padding: 16px 24px;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 16px 16px 0px 0px;
border: 1px solid #DFE1E2;
background: #F9F9FA;
color: #374147;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.ineroptions{
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.innersingle{
    display: flex;
width: 104px;
flex-direction: column;
align-items: center;
gap: 8px;
}
.typetext{
    color: #666D72;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.boldig{
    color: #374147;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.innersingle{
    text-align: center;
    
}
.typequote{
    border-bottom: 1px solid #DFE1E2;
    padding-bottom: 10px;
    width: 103px;
}
.leftofeye{
    color: #374147;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
align-items: center;
gap: 5px;
}
.eyeserver{
    display: flex;
    gap: 16px;
}
.stackcarrior{
    max-width: 1216px;
height: 500px;
flex-shrink: 0;
border-radius: 32px;
border: 8px solid #F9F9FA;
background: #00101D;
margin: auto;
background-image: url(./images/carrierback.png);
display: flex;
justify-content: space-between;
padding-top: 21px;
padding-left: 40px;
padding-right: 48px;
padding-bottom: 20px;
overflow: hidden;
}
.headerforstack{
    display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 37px;
margin-top: 65px;
}
.maintitlecontent{
    font-family: "Open Sans";
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 57.6px */
letter-spacing: 2.4px;
background: linear-gradient(268deg, #0095ED 39.86%, #90DAFF 70.99%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.paraofstack{
    color: #FFF;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 25.6px */
letter-spacing: 0.8px;
max-width: 492px;
}
.stackcarrior .Chamelonhome{
    max-width: 564px;
    height: 423px;
    border: 20px;
}
.stackcarrior .mainaredash{
    max-width: 404px;
    max-height:423px ;

}
.stackcarrior .navcon{
    max-height:423px ;
}
.stackcarrior  .salesnav, .stackcarrior .titlesectiondash,.stackcarrior .dashinforcormer , .stackcarrior .newsearch{
    max-width: 347px;
}
.selectors{
    display: flex;
    gap: 20px;
}
.stackcarrior .selectors .dashinforcormer{
    width: 170px;
}
.stackcarrior .selectors .dashinforcormer .titlesectiondash{
    width: 170px;
}
.sectiontoselect{
    display: flex;
padding-left: 5px;
padding-right: 5px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: #EDEEEE;
color: #374147;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 200% */
}
.classification{
    display: flex;
    flex-direction: column;
    gap: 16px;

}
.forimageofdeep img{
    width: 352px;
    height: 180px;
}
@media (max-width:1300px) {
    .navcon{
        max-width: 120px;
    }
    /* .mainaredash{
        max-width: 567px;
    } */
    .newsearch{
        max-width: 519px;
    }
    .salesnav,.titlesectiondash, .dashinforcormer{
        max-width: 524px;
    }
    .singlecard{
        max-width: 256px;
    }
    .callcardsreview{
        max-width: 1008px;
    }
}
@media (max-width:786px) {
    .stackcarrior{
    flex-direction: column;
    height: auto;
    background-position: bottom;
    background-repeat: no-repeat;
    padding-left: 10px;
    padding-right: 10px;
    }
    .stackcarrior .Chamelonhome{
height: 216px;
    }
    .maintitlecontent{
        font-size: 32px;
        }
        .headerforstack{
            gap: 10px;
        }
        .stackcarrior{
            max-width: 95%;
        }
}