.Mobilechemeleon{
    width: 90%;
    margin: auto;
    background-color: #00101D;
    height: 673px;
    border-radius: 30px;
    background-image: url(./images/Degrade\ \(1\).png);
    background-repeat: no-repeat;
    background-position: bottom right;
padding: 15px;
overflow: hidden;
}
.textarea_mobile{
margin-top: 15px;
text-align: left;
}
.smalltitle_blue{
    color: #0095ED;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.7px;

}
.larger_title{
    font-family: "Open Sans";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 38.4px */
letter-spacing: 1.6px;
background: linear-gradient(268deg, #0095ED 39.86%, #90DAFF 70.99%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-top: 10px;
}
.mobiledesc_chemelon{
    color: #FFF;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 25.6px */
letter-spacing: 0.8px;
margin-top: 37px;
}
.mobile_image_section{
    width: 90%;
    margin: auto;
margin-top: 66px;

}
.mobile_image_section img{
    width: 100%;
}