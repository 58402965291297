.transparent {
    max-width: 1216px;
    flex-shrink: 0;
    border-radius: 32px;
    background: #FFF;
    margin: auto;
    margin-top: 80px;
    padding: 31px;

}

.getbtn {
    cursor: pointer;
}
.undertext{
    font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 21.79px;
letter-spacing: 0.05em;
text-align: center;
color: #0095ED;
margin-top: 40px;
}
.getbtn:hover {
    cursor: pointer;
    background: #0095ED;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.79px;
    letter-spacing: 0.05em;
    text-align: left;
    color: white;

}

.title_transparent {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: auto;
    width: 100%;
    margin-top: 49px;
}

.head_transparent {
    color: #374147;
    text-align: center;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 38.4px */
    letter-spacing: 1.6px;
}

.paraof_transparent {
    color: #374147;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
}

.table_of_features {
    width: 100%;
    margin-top: 65px;
}

.transparent table tr th {
    color: #374147;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 24px */
    letter-spacing: 1px;
    padding: 40px;
    text-align: center;
}

.transparent table {
    border-collapse: collapse;
    width: 100%;
}

.transparent table tr td {
    border: 1px solid #E0E0E0;
    height: 107px;
    text-align: center;
    vertical-align: middle;
    width: 16%;
}
.transparent table tr td:first-child{
    text-align: left;
    width: 50%;
}

.transparent table tr td:first-child {
    border-left: none;
}

.transparent table tr td:last-child {
    border-right: none;
}
.transparent table tr th:first-child{
    text-align: left;
    padding-left: 0px;

}
.transparent table tr:last-child td {
    border-bottom: none;
}

.yerlypadtop {
    padding-top: 40px;
    
}

@media (max-width:1250px) {
    .transparent {
        max-width: 1008px;
        margin-top: 500px;
    }

    .slider-container {
        max-width: 95vw;
    }

}

.mobileversion {
    display: none !important;
}

@media (max-width:1000px) {
    .deskversion {
        display: none !important;
    }

    .mobileversion {
        display: block !important;
    }

    .transparentmobile {
        width: 333px;
        height: auto;
        border-radius: 32px;
        background: #FFF;
        width: 95%;
        margin: auto;
        margin-top: 560px;
    }

    .features {
        display: flex;
        justify-content: space-around;
        color: #374147;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 16.8px */
        letter-spacing: 0.7px;
        margin-top: 40px
    }

    .basic {
        display: flex;
        justify-content: center;
    }

    .featureexplain {
        color: #374147;
        text-align: center;
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 18.2px */
        letter-spacing: 0.65px;
        text-align: center;
        margin-top: 16px;
    }

    .verifiedboxes {
        display: flex;
        margin-top: 16px;
    }

    .induverified {
        flex: 1;
        display: flex;
        justify-content: center;
        height: 107px;
        align-items: center;
        border-top: 1px solid #E0E0E0;
        border-right: 1px solid #E0E0E0;
        background: #FFF;

    }

    .induverified:last-child {
        border-right: none;

    }
}

@media (max-width:786px) {
    .coverofsmallcoursel .Herosection {
        height: auto;
        background-size: auto;
    }

    .coverofsmallcoursel .Herosection {
        background-size: cover;
    }

    .yearlybuttons {
        width: 92vw;
    }

    .transparentmobile {
        /* margin-top: 5px; */
    }

    .slider-container {
        max-width: 95vw;
    }
    .yerlypadtop{
        padding-top: 0px;
    }
    .slidpric{
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }
}

@media (max-width:500px) {
    .head_transparent {
        max-width: 90vw;
        font-size: 24px;

    }
    .transparentmobile {
        margin-top: 620px;
    }

    .paraof_transparent {
        max-width: 257px;
    }

}