.containerwidth2 {
  max-width: 1530px;
  margin: auto;
}

.forproduct .Herosection {
  height: auto;
  background-size: contain;
}

.aboutmini {
  color: #0095ED;

  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 16.8px */
  letter-spacing: 0.7px;
}

.productheading {
  color: #FFF;

  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 57.6px */
  letter-spacing: 2.4px;
  width: 518px;
}

.productpera {
  color: #FFF;

  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
  letter-spacing: 0.8px;
}

.productbtn {
  display: flex;
  width: 375px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #0095ED;
  color: #FFF;

  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  border: none;
}

.standerdheading {
  color: #374147;
  max-width: 679px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 57.6px */
  letter-spacing: 2.4px;
  text-align: center;
}

.standerdpera {
  color: #374147;
  max-width: 679px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
  letter-spacing: 0.8px;
  text-align: center;
}

.standerdmain {
  display: flex;
  align-items: center;
  gap: 74px;
  margin-top: 90px;
}

.standerleft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 37px;
  width: 441px;

}

.standerleft2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 37px;
  width: 706px;
  padding: 123px 0px 120px 30px;

}

.standheding {
  color: #84949E;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1.6px;
}

.standheding2 {
  color: #374147;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1.6px;
}

.standpera {
  color: #374147;
  width: 441px;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
  letter-spacing: 0.8px;
}

.standheding4 {
  background: linear-gradient(268deg, #0095ED 39.86%, #90DAFF 70.99%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 2.4px;
}

.standheding3 {
  color: #FFF;

  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 57.6px */
  letter-spacing: 2.4px;
}

.standleft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 37px;
  width: 559px;


}

.standdmain {
  width: 1216px;
  height: 749px;
  border-radius: 32px;
  background: #00101D;
  margin: auto;

  margin-top: 120px;
  display: flex;
  /* background: linear-gradient(0deg, #00101D 62.3%, rgba(5, 30, 51, 0.00) 89.33%); */
  background-image: url('../../images/product/standbg.png');
  background-size: cover;
  background-repeat: no-repeat;

}

.startedbtn2 {
  display: flex;
  width: 375px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #0095ED;
  color: #FFF;

  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}

.standpera2 {
  color: #FFF;

  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
  letter-spacing: 0.8px;
  width: 492px;
}

.standerright2 {
  width: 595px;
  position: relative;
  padding-top: 70px;


}

.standerright2 div:first-child {
  text-align: center
}

.standerright2 div:last-child {
  position: absolute;
  bottom: 70px;
}

.elips {
  position: absolute;
  top: 242px;
  right: 0px;
}

.sliderheading {
  color: #FFF;
  padding-top: 80px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 57.6px */
  letter-spacing: 2.4px;
  padding-bottom: 48px;
}

.slidercard {
  width: 340px !important;
  height: 230px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 16px 16px 20px 0px rgba(0, 33, 60, 0.10);

  padding-left: 16px;
  padding-top: 16px;
}

.slidercard>p {
  color: #374147;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sliderhed {
  color: #374147;

  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sliderpera {
  font-weight: 300;
}

.containerwidth3 {
  max-width: 1216px;
  margin: 90px auto;
  margin-top: 0;

}

.headerproduct {
  display: flex;
}

.headerproductleft {
  width: 500px;
}

.headerproductright {
  width: 915px;
}

.stanright {
  width: 701px;
}

.slidercard {
  margin-bottom: 30px;
}

.headerpading {
  padding-top: 120px;
  overflow-x: hidden;
}

@media (max-width:1440px) {
  .containerwidth2 {
    max-width: 1217px;
  }
}

@media (max-width:1250px) {

  .containerwidth2,
  .navcontainer {
    max-width: 1008px;
  }

  .containerwidth3,
  .standdmain {
    max-width: 1008px;

  }

  .elips {
    right: -70px !important;
  }

  /* .standleft{
    width: 441px;
  } */
  .stanright {
    width: 701px;
  }

  .productheading {
    font-size: 40px;
  }

  .sliderheading {
    font-size: 40px;
  }

  .Herosection {
    height: 908px;
  }

  .containerwidth3 {
    margin: 40px auto;
  }

  .freestart {
    width: 100%;
    overflow: hidden;
  }

  .standheding3,
  .standheding4 {
    font-size: 40px;
  }

  .standerright2 {
    display: none;
  }

  .standerright4 {
    display: block !important;
    padding-top: 100px;
  }
}

@media (max-width:1080px) {
  .standerright2 {
    padding-left: 23px;
    padding-right: 23px;
    width: 490px;
  }
  .containerwidth3, .standdmain{
    width: 95vw;
  }

  .containerwidth2 {
    max-width: 100vw;
    margin-left: 30px;
  }

  .headerproductright img {
    width: 700px;
  }

  .headerproductright {
    overflow: hidden;
  }

  .standdmain {
    width: 100vw;
  }
}
@media (max-width:900px) {
  .headerproductright img {
    width: 95vw;
  }
  .headerproduct{
    flex-direction: column;
  }
  .headerproductright{
width: 100vw;
  }
}
@media (max-width:768px) {
  .containerwidth2 {
    /* width: 95vw; */
    overflow-x: hidden;
    max-width: 95vw;
    margin-left: 0px;
    margin: auto;
  }
  .forpaymentpage .Herosection {
    height: auto !important;
    background-size: contain !important;
  }

  .headerproduct {
    flex-direction: column;
    /* width: 90vh; */

  }

  .productbtn {
    width: 100%;
    border: 0;
  }

  .startedbtn2 {
    width: 100%;
    border: 0;
  }

  .startedbtn {
    width: 100%;
  }

  .productheading {
    color: #FFF;

    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 38.4px */
    letter-spacing: 1.6px;
  }

  .headerproductleft {
    max-width: 90vw;
    width: 90vw;
    margin: auto;
  }

  .headerproductright {
    width: 90vw;
    margin: auto;
  }

  .standerdmain {
    flex-direction: column;
  }

  .standleft {
    width: 90vw;
    gap: 24px;
  }

  .stanright {
    width: 96%;
  }

  .containerwidth3 {
    width: 95vw;
    margin-top: 40px;
    margin-bottom: 40px;
    /* flex-direction: column-reverse; */
  }

  .standdmain {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }

  .standerleft2 {
    padding-top: 45px;
    width: 332px;
    padding-bottom: 80px;


    padding-left: 15px;
  }

  .standheding3,
  .standheding4 {
    font-size: 32px;
  }

  .standpera2 {
    width: 332px;
  }

  .standpera {
    width: auto;
  }

  .standerright2 {
    width: 332px;
  }

  /* .Herosection {
    height: 100%;
  } */
  .standerleft {
    width: 90vw;
    gap: 24px;
  }

  .standerdheading {
    font-size: 32px;
  }

  .sliderheading {
    font-size: 32px;
    color: #374147;
  }

  .slidercard {
    /* box-shadow: none; */
    /* padding-bottom: 50px; */
  }

  .standheding,
  .standheding2 {
    font-size: 24px;
  }

  .standdmain {
    margin-top: 0;
  }

  .standerright4 {
    width: 85vw;
    display: block !important;
    margin: auto;
  }

  .standerright4 {
    padding-top: 0px;
    padding-bottom: 80px;
  }

  .reversecolum {
    flex-direction: column-reverse;
  }

  .standerright2 {
    display: none;
  }

  .headerpading {
    padding-top: 33px;
  }
/* .forproduct .productheading, .productpera{
  color: black;
} */
  .forproduct .Herosection {
    height: auto !important;
    background-size: auto;
  }
}