.navregis{
    width: 100%;
    display: flex;
    gap: 20px;
}
.sectionreg{
    width: 32%;
    color: rgb(149, 147, 147);
}
.indicatereg{
    background-color: rgb(149, 147, 147);
    height: 16px;
    width: 100%;
    margin-top: 10px;
    border-radius: 4px;
}
.invalid-feedback{
    color: red;
}
.errormessage{
    color: red;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}
.regactive{
    color: #0095ED;
    cursor: pointer;
}
.indiactive{
 background-color: #0095ED;   
}
.forpromosection{
    width: 100%;
height: 159px;
border-radius: 8px;
background: #F3F3F3;
padding: 35px;
padding-top: 16px;
}
.contentpromo{
    display: flex;
    justify-content: space-between;
    color: #676767;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 105%; /* 16.8px */
letter-spacing: 0.8px;
margin-top: 16px;
}
.contentpromo:first-child{
    margin-top: 0px;
}
.boltext{
    color: #000;
    font-weight: 600;
}
@media (max-width: 1340px) {
    .navregis {
        width: 90vw;
        margin: auto;
    }
}
@media (max-width:786px) {
    .navregis{
        gap: 10px;
        font-size: 12px;
    }
}